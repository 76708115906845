import React from 'react';
import './App.css';
import './Registro.css';
import localforage from 'localforage'

import {
  Routes,
  Route,
  BrowserRouter,
} from 'react-router-dom'

const AutologinComponent = React.lazy(() => import('./Components/Autologin'))
const LoginComponent = React.lazy(() => import('./Components/Login'))
//const InicioComponent = React.lazy(() => import('./Components/Inicio'))
const InicioComponent = React.lazy(() => import('./Components/InicioV2'))
const RegistroComponent = React.lazy(() => import('./Components/Registro'))
const RecuperarPassComponent = React.lazy(() => import('./Components/RecuperarPass'))
const CambiarPassComponent = React.lazy(() => import('./Components/CambiarPass'))
const IndexComponent = React.lazy(() => import('./Components/Index'))

//const pathroute = window.location.pathname;

function App() {

  const compruebaInicio = () => {
    localforage.getItem('credenciales').then(function (val) {
      console.log(typeof val);
      if (val !== null && val !== 'null') {
        //console.log('true')
        return true;
      } else {
        return false;
        window.location.href = '/inicio'
      }
    }).catch(function (err) {
      return false;
      window.location.href = '/inicio'

    })
    return true;
  }

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={'Cargando...'}>
          <Routes>
            <Route path="/autologin" name="Autologin" element={<AutologinComponent />} />
            {/* <Route exact path="/login" name="Login" element={<LoginComponent />} /> */}
            <Route exact path="/inicio" name="Inicio" element={<InicioComponent />} />
            {/* <Route exact path="/registro" name="Registro" element={<RegistroComponent />} />
            <Route exact path="/recuperar" name="Recuperar Contraseña" element={<RecuperarPassComponent />} />
            <Route exact path="/cambiar-password" name="Cambiar Contraseña" element={<CambiarPassComponent />} /> */}
            <Route exact path="/" name="Home" element={<IndexComponent callback={compruebaInicio} />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
